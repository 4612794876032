
import { IDateRange } from '@/modules/system/types';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

interface Tick {
  time: Date;
  color: string;
  sign: string;
  label: string;
  selected: boolean;
}

@Component
export default class TimeLine extends Vue {
  @Prop({ default: () => { } })
  readonly value: IDateRange | undefined;

  @Prop({ default: () => new Date() })
  readonly date: Date | undefined;

  @Prop({ default: () => new Date() })
  readonly from: string | undefined;

  @Prop({ default: () => new Date() })
  readonly to: string | undefined;

  @Prop({ default: () => 15 })
  readonly tick: number | undefined;

  @Prop({ default: () => false })
  readonly disabled: boolean | undefined;

  @Prop({ default: () => false })
  readonly ready: boolean | undefined;

  @Prop({ default: () => false })
  readonly busy: Array<IDateRange> | undefined;

  selectedFrom: number | null = null;
  selectedTo: number | null = null;
  invalidRange = false;

  @Watch('ready')
  onReady() {
    if (this.ready && this.value && this.value.from) {
      const rangeFrom: Date = new Date(this.value.from);
      let index = -1;
      for (let i = 0; i < this.ticks.length; i++) {
        if (this.ticks[i].time.getTime() > rangeFrom.getTime()) {
          index = i;
          break;
        }
      }

      if (index >= 0) {
        setTimeout(() => { this.scrollToTick('tick_' + index) }, 100);
      }
    }
  }

  get ticks(): Array<Tick> {
    this.$emit('invalidRange', false);

    const date: Date = new Date(this.date || new Date());
    const dateFrom = new Date(date.getTime()).parseTime(this.from);
    const dateTo = new Date(date.getTime()).parseTime(this.to);
    let dateCur = dateFrom;

    const rangeFrom: Date | null = this.value && this.value.from ? new Date(this.value.from) : null;
    const rangeTo: Date | null = this.value && this.value.to ? new Date(this.value.to) : null;

    const list: Array<Tick> = [];
    let i = 0;
    let hour = 0;
    while (dateCur.getTime() < dateTo.getTime()) {
      const tick: Tick = {
        time: new Date(dateCur.getTime()),
        color: 'success',
        sign: '',
        label: dateCur.formatTime(),
        selected: false
      }

      if (this.busy) {
        tick.selected = this.busy.some(r => r.from && r.to && dateCur.getTime() >= r.from.getTime() && dateCur.getTime() < r.to.getTime())
      }

      if (tick.selected) {
        if (rangeFrom && dateCur.getTime() >= rangeFrom.getTime() && rangeTo && dateCur.getTime() < rangeTo.getTime()) {
          tick.color = 'error';
          this.$emit('invalidRange', true);
        } else {
          tick.color = 'secondary';
        }
      } else {
        if (this.selectedFrom && i >= this.selectedFrom) {
          if ((this.selectedTo && i <= this.selectedTo) || (!this.selectedTo && i === this.selectedFrom)) {
            tick.color = this.invalidRange ? 'error' : 'primary';
          }
        }

        if (rangeFrom && dateCur.getTime() >= rangeFrom.getTime()) {
          if (rangeTo && dateCur.getTime() < rangeTo.getTime()) {
            tick.color = 'primary';
          }
        }
      }

      if (dateCur.getHours() !== hour) {
        hour = dateCur.getHours();
        tick.sign = hour < 10 ? '0' + hour : '' + hour;
      }

      list.push(tick);
      i++;

      dateCur = dateCur.addMin(Number(this.tick) || 5);
    }
    return list;
  }

  selectRange(tick: number) {
    if (this.selectedFrom === null) {
      this.selectedFrom = tick;
    } else {
      if (this.selectedTo && this.selectedFrom < this.selectedTo && !this.invalidRange) {
        const range: IDateRange = {
          from: this.ticks[this.selectedFrom].time,
          to: this.ticks[this.selectedTo].time
        }
        this.$emit('input', range);
      }
      this.selectedFrom = null;
      this.selectedTo = null;
      this.invalidRange = false;
    }
  }

  prepareRange(tick: number) {
    if (this.selectedFrom !== null) {
      this.selectedTo = tick;
      if (this.busy) {
        this.invalidRange = this.ticks
          .filter((t, index) => index >= this.selectedFrom! && index < this.selectedTo!)
          .some(t => this.busy!.some(r => r.from && r.to && t.time.getTime() >= r.from.getTime() && t.time.getTime() < r.to.getTime()));
      }
    }
  }

  scrollToTick(id: string) {
    const el = document.getElementById(id);
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', inline: 'center' });
    }
  }
}
